<template>
  <base-layout>
    <div class="score-wrapper scoreboard">
      <div class="title-wrap relative">
        <h3 class="page-titles">
          Reports
        </h3>
        <p>Select the campaign to view results of the candidates and customise your requirements to filter out the top candidates for your choice.</p>
        <div class="profile">
          <the-profile class="print:hidden" />
        </div>
      </div>

      <div
        v-if="$store.state.CAMPAIGNS.organisationCampaigns[0]"
        class="flex flex-col flex-auto"
      >
        <top-menu
          :show-go-back-btn="showGoBackBtn"
          :back-path="{ name: 'Analytics', params: { campaignId: this.$route.params.campaignId } }"
          class="mb-5"
        />
        <div
          class="w-full h-full"
        >
          <router-view
            v-if="($store.state.CAMPAIGNS.organisationCampaigns && $route.name != 'Personality') || ($store.state.ANALYTICS.currentCampaign && $route.name === 'Personality')"
            :key="$route.fullPath"
          />
          <div
            class="flex items-center justify-center w-full h-full"
            v-else
          >
            <button class="btn btn-lg btn-circle btn-ghost loading" />
          </div>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue'
// import CampaignListDropdown from './components/CamaignListDropdown.vue'
import TopMenu from './components/TopMenu.vue'
import TheProfile from '@/components/TheProfile.vue'
import { mapGetters } from 'vuex'

export default {
  components: { BaseLayout, TopMenu, TheProfile },
  computed: {
    ...mapGetters({ campaigns: 'CAMPAIGNS/getCampaigns' }),
    showGoBackBtn () {
      return this.$route.name !== 'Analytics' && (!!this.$store.state.ANALYTICS.currentCampaign && !!this.$store.state.ANALYTICS.currentCampaign.Questionnaires && this.$store.state.ANALYTICS.currentCampaign.Questionnaires.length > 0)
    }
  },
  async created () {
    if (this.$route.params.page) {
      const parsed = parseInt(this.$route.params.page)
      if (!isNaN(parsed)) {
        this.$store.commit('CAMPAIGNS/SET_CURRENT_PAGE', parsed)
      }
    }
    await this.$store.dispatch('CAMPAIGNS/getOrganisationCampaigns')

    if (this.$route.params.campaignId) {
      this.$store.dispatch('ANALYTICS/setCurrentCampaign', this.$route.params.campaignId)
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.campaignId) {
      this.$store.dispatch('ANALYTICS/setCurrentCampaign', to.params.campaignId)
    }
    next()
  }
}
</script>

<style lang="scss">
  @import '../Scoreboard/scoreboard.scss';
  </style>
